import React, { useEffect, useState } from "react";
import Navbar from "../Home/Navbar";
import { Link } from "react-router-dom";
import Footer from "../Home/Footer";
import CtaFooter from "../../Components/CtaFooter";
import { Config } from "../../Config";
import axios from "axios";
import Loader from "../../Components/Loader";

const KinaxinSolution = () => {
  const [loading, setLoading] = useState(true);
  const [kinaxis_work, setkinaxisWork] = useState({});
  const [kinaxis_mission, setkinaxisMission] = useState([]);
  const [kinaxis_know, setkinaxisKnow] = useState({});
  const [kinaxis_connect, setkinaxisConnect] = useState({});
  const [kinaxis_cta, setkinaxisCta] = useState([]);
  const [kinaxis_project, setkinaxisProject] = useState([]);
  const [kinaxis_case, setkinaxisCase] = useState({});
  const [kinaxis_casestudy, setkinaxisCaseStudy] = useState([]);

  useEffect(() => {
    axios
      .get(`${Config.apiUrl}/kinaxis`, {
        headers: {
          //'Authorization': 'Bearer ',
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const data = response.data.kinaxis_page;
        setkinaxisWork(data.kinaxis_work);
        setkinaxisMission(data.kinaxis_mission);
        setkinaxisKnow(data.kinaxis_know);
        setkinaxisConnect(data.kinaxis_connect);
        setkinaxisCta(data.kinaxis_cta);
        setkinaxisProject(data.kinaxis_project);
        setkinaxisCase(data.kinaxis_case);
        setkinaxisCaseStudy(data.kinaxis_casestudy);
      })
      .catch((error) => {
        console.error("There was a problem fetching the data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  document.title = "Kinaxis Solutions | plan4ai";

  return (
    <>
      {loading && <Loader />}
      <Navbar />

      <section
        className="page-header py-5"
        style={{
          backgroundImage:
            "linear-gradient(rgba(255, 255, 255, 0.337), rgba(0, 0, 0, 0.57) 0%), url('https://images.unsplash.com/photo-1451187580459-43490279c0fa?q=80&w=1472&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
        }}
      >
        <div className="container-box py-4">
          <div className="text-white">
            <h1 className="fw-bold display-5">Kinaxis Solution </h1>
            <div className="line"></div>
            <p className="mt-4 header-path-link">
              <Link
                to={`${Config.appUrl}`}
                className="text-orange fw-bold text-decoration-none"
              >
                {" "}
                Home
              </Link>
              <i className="bi bi-arrow-right mx-3"></i>
              Solution
              <i className="bi bi-arrow-right mx-3"></i>
              Kinaxis Solution
            </p>
          </div>
        </div>
      </section>

      <section className="py-5">
        <div className="container-box py-md-4">
          <div className="top-heading mb-4">
            <p className="text-orange mb-1 text-uppercase">
              {kinaxis_work.kinaxis_title}
            </p>
            <span className="title-h2 d-block text-dark-blue">
              <b>{kinaxis_work.kinaxis_subtitle}</b>
            </span>

            <p className="paragraph mt-3">
              <div
                dangerouslySetInnerHTML={{
                  __html: kinaxis_work.kinaxis_description,
                }}
              />
            </p>
          </div>

          <h4 className="text-secondary fw-bold text-capitalize">
            {kinaxis_work.kinaxis_text}
          </h4>

          <div className="mt-4">
            {kinaxis_mission.map((mission) => (
              <div key={mission.id} className="areas-box">
                {mission.name}
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="py-5 bg-style-img">
        <div className="container-box py-md-5">
          <div className="row">
            <div className="col-md-6 pe-md-5">
              <img
                src={`${Config.imageUrl}/${kinaxis_know.kinaxis_know_image}`}
                alt=""
                className="w-100 p-1 border border-4"
              />
            </div>

            <div className="col-md-6 mt-md-3 mt-4">
              <span className="title-h2 d-block text-dark-blue">
                <b>{kinaxis_know.kinaxis_know_title}</b>
              </span>
              <div className="line mt-3 bg-orange"></div>

              <div className="mt-4">
                <p className="paragraph">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: kinaxis_know.kinaxis_know_description,
                    }}
                  />
                </p>

                <div className="mt-5">
                  <Link
                    to={`${Config.appUrl}about-us`}
                    className="style-btn shadow"
                  >
                    <span>{" > "}</span>{" "}
                    <div className="d-inline">
                      {" "}
                      {kinaxis_know.kinaxis_know_image}
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5">
        <div className="container-box py-md-5">
          <div className="top-heading mb-4">
            <p className="text-orange mb-1 text-uppercase">
              {kinaxis_connect.kinaxis_connect_title}
            </p>
            <span className="title-h2 d-block text-dark-blue">
              <b>{kinaxis_connect.kinaxis_connect_subtitle}</b>
            </span>
          </div>

          <h4 className="text-secondary fw-bold text-capitalize">
            {kinaxis_connect.kinaxis_connect_text}
          </h4>

          <div className="row align-items-center">
            <div className="col-md-7">
              <div
                dangerouslySetInnerHTML={{
                  __html: kinaxis_connect.kinaxis_connect_description,
                }}
              ></div>
            </div>

            <div className="col-md-5 ps-md-5 mt-md-0 mt-5 text-md-start text-center">
              <span className="bordered-text">
                {kinaxis_connect.kinaxis_connect_textb}
                <span className="orange-border">
                  {kinaxis_connect.kinaxis_connect_texto}
                </span>
                {kinaxis_connect.kinaxis_connect_textbl}
              </span>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5 contact-bg">
        <div className="py-md-5 container-box">
          <div className=" cta-section text-center">
            <h2>{kinaxis_cta.kinaxis_cta_title}</h2>
            <p className="paragraph text-white">
              <div
                dangerouslySetInnerHTML={{
                  __html: kinaxis_cta.kinaxis_cta_description,
                }}
              ></div>
            </p>

            <div className="mt-5">
              <Link to={`${Config.appUrl}contact`} className="style-btn shadow">
                <span>{" > "}</span>{" "}
                <div className="d-inline">{kinaxis_cta.kinaxis_cta_btn}</div>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5 bg-style-img">
        <div className="container-box py-md-5">
          {kinaxis_project.map((project, index) => (
            <div key={index} className="row mb-5">
              <div className="col-md-6 pe-md-5">
                <img
                  src={`${Config.imageUrl}/instructors/${project.image}`}
                  alt=""
                  className="w-100 p-1 border border-4"
                />
              </div>

              <div className="col-md-6 mt-4">
                <span className="title-h2 d-block text-dark-blue">
                  <b>{project.title}</b>
                </span>
                <div className="line mt-3 bg-orange"></div>

                <div className="mt-4">
                  <p className="paragraph lh-lg">
                    <div
                      dangerouslySetInnerHTML={{ __html: project.description }}
                    ></div>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="py-5">
        <div className="container-box pt-md-5">
          <div className="top-heading mb-4">
            <p className="text-orange mb-1 text-uppercase">
              {kinaxis_case.kinaxis_casestudy_title}
            </p>
            <span className="title-h2 d-block text-dark-blue">
              <b>{kinaxis_case.kinaxis_casestudy_subtitle}</b>
            </span>
          </div>

          <div className="row">
            {kinaxis_casestudy.map((casestudy, index) => (
              <div className="col-md-4 mt-5 " key={index}>
                <div className="d-flex flex-wrap bd-industry-box">
                  <div className="bd-top-portion">
                    <div>
                      <h3 className="paragraph">{casestudy.title}</h3>
                      {/* <p>
                                            {item.desc}
                                        </p> */}
                      <div>
                        <Link to="#">{casestudy.btn}</Link>
                      </div>
                    </div>
                  </div>
                  <div className="w-100 bg-black overflow-hidden">
                    <img
                      src={`${Config.imageUrl}/gallery/${casestudy.image}`}
                      className="w-100 "
                      height="250"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <CtaFooter />

      <Footer />
    </>
  );
};

export default KinaxinSolution;
