import React, { useEffect, useState } from "react";
import Navbar from "../Home/Navbar";
import { Link } from "react-router-dom";
import { Config } from "../../Config";
import Footer from "../Home/Footer";
import "../../assets/css/privacy-policy.css";
import axios from "axios";
import Loader from "../../Components/Loader";
const PrivacyPolicy = () => {
  const [loading, setLoading] = useState(true);
  const [privacyData, setPrivacyData] = useState("");
  document.title = "Privacy Policy | Plan4AI";

  useEffect(() => {
    axios
      .get(`${Config.apiUrl}/policy`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const data = response.data;
        setPrivacyData(data.policy.policy_description);
      })
      .catch((error) => {
        console.error("There was a problem fetching the data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  return (
    <>
      {loading && <Loader />}
      <Navbar />
      {/* Banner-section */}
      <section
        className="page-header py-5"
        style={{
          backgroundImage:
            "linear-gradient(#ffffff56, #00000091 60%), url('https://images.unsplash.com/photo-1510519138101-570d1dca3d66?q=80&w=1447&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
          backgroundPosition: "bottom",
          // backgroundSize: "contain",
        }}
      >
        <div className="container-box py-4">
          <div className="text-white">
            <h1 className="fw-bold display-5">Privacy Policy </h1>
            <div className="line"></div>
            <p className="mt-4 header-path-link">
              <Link
                to={`${Config.appUrl}`}
                className="text-orange fw-bold text-decoration-none"
              >
                {" "}
                Home
              </Link>
              <i className="bi bi-arrow-right mx-3"></i>
              Privacy Policy
            </p>
          </div>
        </div>
      </section>
      {/* Banner-section end */}

      {/* page-Content */}
      <section className="py-4">
        <div className="container-box pt-md-4">
          <div>
            <div className="privacy-content">
              <span
                dangerouslySetInnerHTML={{
                  __html: privacyData,
                }}
              />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
