import React, { useEffect, useState } from "react";
import logo from "../../assets/Images/logo.png";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { Config } from "../../Config";
import axios from "axios";
import { RotatingLines } from "react-loader-spinner";

const Footer = () => {
  const [firstName, setFname] = useState("");
  const [lastName, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [industry, setIndustry] = useState("");
  const [interested, setInterested] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    industry: "",
    interested: "",
    message: "",
  });

  const handleInputChange = (setState, value, fieldName) => {
    setState(value);

    if (fieldName === "email" || fieldName === "phone") {
      if (fieldName === "email") {
        if (!value.trim()) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [fieldName]: "Email is required",
          }));
        } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [fieldName]: "Invalid email format",
          }));
        } else {
          setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
        }
      }
      if (fieldName === "phone") {
        if (!value.trim()) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [fieldName]: "Phone number is required",
          }));
        } else if (value.length !== 10) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [fieldName]: "Phone number must be of 10 digits",
          }));
        } else {
          setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
        }
      }
    } else {
      if (value.trim() !== "") {
        setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [fieldName]: `${
            fieldName.charAt(0).toUpperCase() + fieldName.slice(1)
          } is required`,
        }));
      }
    }
  };

  // form validations
  const validateForm = () => {
    const newErrors = {};

    if (!firstName) {
      newErrors.firstName = "First name is required";
    }
    if (!lastName) {
      newErrors.lastName = "Last name is required";
    }
    if (!email) {
      newErrors.email = "Email is required";
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      newErrors.email = "Invalid email format";
    }
    if (!phone) {
      newErrors.phone = "Phone number is required";
    } else if (phone.length !== 10) {
      newErrors.phone = "Phone number must be of 10 digits";
    }
    if (!industry) {
      newErrors.industry = "Industry is required";
    }
    if (!interested) {
      newErrors.interested = "Interested is required";
    }
    if (!message) {
      newErrors.message = "Your Query is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmitData = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const formData = new FormData();
      formData.append("fname", firstName);
      formData.append("lname", lastName);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("industry", industry);
      formData.append("interest", interested);
      formData.append("message", message);

      setLoading(true);

      axios
        .post(`${Config.apiUrl}/contact`, formData, {
          headers: {
            //'Authorization': 'Bearer ',
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          //status: 1, company: {…}, message: "Message Succesfully sent..."
          if (response.data.message === "Message Succesfully sent...") {
            swal("Thank You!", "Your data has been sent successfully", {
              icon: "success",
            });
            setFname("");
            setLname("");
            setEmail("");
            setPhone("");
            setIndustry("");
            setInterested("");
            setMessage("");
            setLoading(false);
          } else {
            swal("Error!", "Something went Wrong! Please Try Again.", {
              icon: "error",
            });
          }
        })
        .catch((error) => {
          console.error("There was a problem fetching the data:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    axios
      .get(`${Config.apiUrl}/contactpage`, {
        headers: {
          //'Authorization': 'Bearer ',
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const data = response.data.contact_page;
        setData(data.contact);
      })
      .catch((error) => {
        console.error("There was a problem fetching the data:", error);
      });
  }, []);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <RotatingLines color="#2BAD60" height={100} width={100} />
        </div>
      ) : (
        <footer className="pt-5 footer bg-style-img">
          <div className="container-box">
            <div className="">
              <div className="row pb-5 ">
                <div className="col-md-3 mt-5 pe-md-5">
                  <Link
                    to={`${Config.appUrl}`}
                    className="navbar-brand "
                    href="#"
                  >
                    <img src={logo} alt="Logo" width="200"></img>
                  </Link>

                  <div className="mt-4 footer-links">
                    <p className="paragraph ">
                    Transforming Supply Chains, Driving Success
                    </p>

                    <div className="social-links mt-4">
                      <a
                        href="https://www.facebook.com/profile.php?id=61557210575836&mibextid=LQQJ4d"
                        target="_blank"
                      >
                        <i className="bi bi-facebook text-white"></i>
                      </a>
                      <a href="https://twitter.com/Plan4ai" target="_blank">
                        <i className="bi bi-twitter text-white"></i>
                      </a>
                      <a
                        href="https://www.linkedin.com/company/98798864/admin/feed/posts/"
                        target="_blank"
                      >
                        <i className="bi bi-linkedin text-white"></i>
                      </a>
                      <a
                        href="https://www.instagram.com/plan4ai?igsh=MXc3aDk0czZubmVudA%3D%3D&utm_source=qr"
                        target="_blank"
                      >
                        <i className="bi bi-instagram text-white"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 mt-5 pe-md-4">
                  <h4 className="fw-bold mb-5">CONTACT DETAILS</h4>
                  <div className="mt-4 footer-links">
                    <p className="">
                      <i className="bi bi-telephone-inbound me-3"> </i>
                      <a
                        href="tell:+91 9013783898"
                        className="text-decoration-none d-inline paragraph pe-2"
                      >
                        +1 {data.contact_number_usa}
                      </a>
                    </p>

                    <a href={`mailto:${data.contact_mails}`} className="d-flex">
                      <i className="bi bi-envelope me-3"></i>
                      {data.contact_mails}
                    </a>

                    <p className="fw-medium fs-6 d-flex text-dark-blue mt-3">
                      <i className="bi bi-geo-alt me-3"></i>
                      {/* 3000 Crossings Blvd Lancaster, PA 17601 */}
                      <span className="pe-4">{data.contact_address_usa}</span>
                    </p>
                  </div>
                </div>

                <div className="col-md-6 mt-5">
                  <h4 className="fw-bold mb-5 text-uppercase">
                    Fill Out the form for more information
                  </h4>

                  <form action="" onSubmit={handleSubmitData}>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <input
                          type="text"
                          value={firstName}
                          onChange={(e) =>
                            handleInputChange(
                              setFname,
                              e.target.value,
                              "firstName"
                            )
                          }
                          className="form-control py-2 rounded-0"
                          placeholder="First Name"
                        />
                        {errors.firstName && (
                          <span className="text-danger">
                            {errors.firstName}
                          </span>
                        )}
                      </div>
                      <div className="col-md-6 mb-3">
                        <input
                          type="text"
                          value={lastName}
                          onChange={(e) =>
                            handleInputChange(
                              setLname,
                              e.target.value,
                              "lastName"
                            )
                          }
                          className="form-control py-2 rounded-0"
                          placeholder="Last Name"
                        />
                        {errors.lastName && (
                          <span className="text-danger">{errors.lastName}</span>
                        )}
                      </div>

                      <div className="col-md-6 mb-3">
                        <input
                          type="text"
                          value={email}
                          onChange={(e) => {
                            const newValue = e.target.value.replace(/\s/g, "");
                            handleInputChange(setEmail, newValue, "email");
                          }}
                          placeholder="Business Email"
                          className="form-control py-2 rounded-0"
                        />
                        {errors.email && (
                          <span className="text-danger">{errors.email}</span>
                        )}
                      </div>
                      <div className="col-md-6 mb-3">
                        <input
                          type="text"
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key))
                              event.preventDefault();
                          }}
                          value={phone}
                          onChange={(e) =>
                            handleInputChange(setPhone, e.target.value, "phone")
                          }
                          placeholder="Phone Number"
                          className="form-control py-2 rounded-0"
                        />
                        {errors.phone && (
                          <span className="text-danger">{errors.phone}</span>
                        )}
                      </div>

                      <div className="col-md-6 mb-3">
                        <select
                          value={industry}
                          onChange={(e) =>
                            handleInputChange(
                              setIndustry,
                              e.target.value,
                              "industry"
                            )
                          }
                          className="form-select py-2 rounded-0"
                        >
                          <option value="">Select Industry</option>
                          <option value="Education">Education</option>
                          <option value="Automotive/Service Part">
                            Automotive/Service Part
                          </option>
                          <option value="Consumer Goods">Consumer Goods</option>
                          <option value="Food & Beverage">
                            Food & Beverage
                          </option>
                          <option value="High Tech/Electronics">
                            High Tech/Electronics
                          </option>
                          <option value="Pharmaceutical and Life Sciences">
                            Pharmaceutical and Life Sciences
                          </option>
                          <option value="Retail">Retail</option>
                          <option value="Third Party Logistics">
                            Third Party Logistics
                          </option>
                          <option value="Saffing and Recruitment service">
                            Staffing and Recruitment service
                          </option>
                          <option value="Other">Other</option>
                        </select>
                        {errors.industry && (
                          <span className="text-danger">{errors.industry}</span>
                        )}
                      </div>

                      <div className="col-md-6 mb-3">
                        <select
                          value={interested}
                          onChange={(e) =>
                            handleInputChange(
                              setInterested,
                              e.target.value,
                              "interested"
                            )
                          }
                          className="form-select py-2 rounded-0"
                        >
                          <option value="">I'm interested in</option>
                          <option value="More Information">
                            More Information
                          </option>
                          <option value="Speak with an Expert">
                            Speak with an Expert
                          </option>
                          <option value="Setting up a demo">
                            Setting up a demo
                          </option>
                          <option value="Partner opportunities">
                            Partner Opportunities
                          </option>
                          <option value="Training">Training</option>
                          <option value="Consulting">Consulting</option>
                          <option value="Other">Other</option>
                        </select>
                        {errors.interested && (
                          <span className="text-danger">
                            {errors.interested}
                          </span>
                        )}
                      </div>

                      <div className="col-12">
                        <textarea
                          value={message}
                          onChange={(e) =>
                            handleInputChange(
                              setMessage,
                              e.target.value,
                              "message"
                            )
                          }
                          className="form-control py-2 rounded-0"
                          placeholder="Provide information so we can connect you with the Expert best suitable for your challenges"
                          rows="4"
                        ></textarea>
                        {errors.message && (
                          <span className="text-danger">{errors.message}</span>
                        )}
                      </div>

                      <div className="col-md-12 text-center mt-5">
                        {/* <button className=""> {loading ? 'Submitting..' : 'Submit'} </button> */}

                        <Link
                          onClick={handleSubmitData}
                          className="style-btn bg-dark"
                        >
                          <span>{" > "}</span>
                          <div className="d-inline text-white">
                            {loading ? "Submitting.." : "Send Query"}
                          </div>
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="py-4 px-md-5 px-4 border-top bg-white">
            <div className="d-md-flex justify-content-between align-items-center  ">
              <div className="paragraph fs-6">
                <p className="mb-1 text-uppercase">&copy; 2024 Plan4ai</p>
                <p className="mb-0 text-capitalize">
                  This site is designed and maintained by 
                  <a
                    href="https://www.zithas.com"
                    target="_blank"
                    className="text-decoration-none text-dark-blue fw-bold"
                  >
                    &nbsp;Zithas Technologies
                  </a>
                </p>
              </div>
              <div className="text-md-end mt-md-0 mt-4">
                <p className="mb-0 text-uppercase paragraph fs-6">
                  Sitemap |
                  <Link
                    to={`${Config.appUrl}privacy-policy`}
                    className="nav-link d-inline-block"
                    id="privacyp"
                  >
                   &nbsp; Privacy Policy
                  </Link>
                </p>
              </div>
              {/* Copyrights &copy; 2023 - Plan4AI
                            Designed by
                            <a href="https://www.zithas.com/" target='_blank' className='text-decoration-none' style={{ color: "#ed4c38" }}> Zithas Technologies </a> */}
            </div>
          </div>
        </footer>
      )}
    </>
  );
};

export default Footer;
