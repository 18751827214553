import React, { useState, useEffect } from "react";
import Navbar from "../Home/Navbar";
import { Link, useParams } from "react-router-dom";
import Footer from "../Home/Footer";
import { Config } from "../../Config";
import Loader from "../../Components/Loader";
import axios from "axios";
import serviceBanner from "../../assets/Images/serviceBanner.jpg";

const ImplementationService = () => {
  const { serviceSelected } = useParams();

  const [loading, setLoading] = useState(true);
  const [service, setService] = useState({});

  const loadData = () => {
    setLoading(true);
    try {
      axios
        .get(`${Config.apiUrl}/service/${serviceSelected}`, {
          headers: {
            //'Authorization': 'Bearer ',
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setLoading(false);
          setService(response.data.service);
        });
    } catch (error) {
      setLoading(false);
      console.error("There was a problem fetching the data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
    window.scrollTo({
      top: 0,
      left: document.body.scrollWidth,
      behavior: "smooth", // Optional: Adds smooth scrolling behavior
    });
  }, [serviceSelected]);
  document.title = `${service.page_name} | Plan4AI`;

  return (
    <>
      {loading && <Loader />}
      <Navbar />

      <section
        className="page-header py-5"
        style={{
          backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.337), rgba(0, 0, 0, 0.6) 0%), url(${serviceBanner})`,
          backgroundPosition: "center",
        }}
      >
        {/* https://images.unsplash.com/photo-1488190211105-8b0e65b80b4e?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D */}
        <div className="container-box py-4">
          <div className="text-white">
            <h1 className="fw-bold display-5">{service.page_name}</h1>
            <div className="line"></div>
            <p className="mt-4 header-path-link">
              <Link
                to={`${Config.appUrl}`}
                className="text-orange fw-bold text-decoration-none"
              >
                {" "}
                Home
              </Link>
              <i className="bi bi-arrow-right mx-3"></i>
              {service.page_name}
            </p>
          </div>
        </div>
      </section>

      <section className="py-5 service">
        <div className="container-box py-md-5">
          <div className="mb-4 head-content">
            <h2 className="text-dark-blue fw-bold text-capitalize">
              {service.point_title}
            </h2>
            <p className="paragraph lh-lg mt-4 fs-5">
              <div
                dangerouslySetInnerHTML={{
                  __html: service.point_description,
                }}
              />
            </p>
          </div>
          <div className="row about-section pt-4">
            <div className="col-md-6 pe-md-5">
              <div>
                <img
                  src={`${Config.imageUrl}/ourservice/${service.main_image}`}
                  className="w-100 mb-5 object-fit-cover p-1 border border-4"
                  style={{ height: "315px" }}
                  alt=""
                />

                <img
                  src={`${Config.imageUrl}/ourservice/${service.main_image2}`}
                  className="w-100 object-fit-cover p-1 border border-4"
                  style={{ height: "315px" }}
                  alt=""
                />
              </div>
            </div>

            <div className="col-md-6 mt-md-0 mt-5">
              <div>
                <div className="">
                  {/* accordian */}
                  <div
                    className="accordion accordion-flush"
                    id="accordionFlushExample"
                  >
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwo"
                          aria-expanded="false"
                          aria-controls="flush-collapseTwo"
                        >
                          <i className={`${service.point_icon2} me-3`}></i>
                          {service.point_title2}
                        </button>
                      </h2>
                      <div
                        id="flush-collapseTwo"
                        className="accordion-collapse collapse show"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p className="paragraph">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: service.point_description2,
                              }}
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseThree"
                          aria-expanded="false"
                          aria-controls="flush-collapseTwo"
                        >
                          <i className={`${service.point_icon3} me-3`}></i>
                          {service.point_title3}
                        </button>
                      </h2>
                      <div
                        id="flush-collapseThree"
                        className="accordion-collapse collapse "
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p className="paragraph">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: service.point_description3,
                              }}
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFour"
                          aria-expanded="false"
                          aria-controls="flush-collapseFour"
                        >
                          <i className={`${service.point_icon4} me-3`}></i>
                          {service.point_title4}
                        </button>
                      </h2>
                      <div
                        id="flush-collapseFour"
                        className="accordion-collapse collapse "
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p className="paragraph">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: service.point_description4,
                              }}
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFive"
                          aria-expanded="false"
                          aria-controls="flush-collapseFive"
                        >
                          <i className={`${service.point_icon5} me-3`}></i>
                          {service.point_title5}
                        </button>
                      </h2>
                      <div
                        id="flush-collapseFive"
                        className="accordion-collapse collapse "
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p className="paragraph">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: service.point_description5,
                              }}
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5 bg-light-gray bg-style-img">
        <div className="container-box py-md-5">
          <div className="top-heading">
            <p className="text-orange mb-1 text-uppercase">
              {service.service_title}
            </p>
            <span className="title-h2 d-block">
              <b>{service.service_subtitle}</b>
            </span>
          </div>

          <div className="row mt-3 pt-4 service-feature">
            <div className="col-md-6">
              <div>
                <i className={`${service.service_icon} fs-2`}></i>
              </div>
              <h5 className="fs-5 text-uppercase letter-spacing mb-4 ">
                {service.service_text}
              </h5>
              <p className="paragraph">
                <div
                  dangerouslySetInnerHTML={{
                    __html: service.service_description,
                  }}
                />
              </p>
            </div>

            <div className="col-md-6 mt-mb-4">
              <div>
                <i className={`${service.service_icon2} fs-2`}></i>
              </div>
              <h5 className="fs-5 text-uppercase letter-spacing mb-4 ">
                {service.service_text2}
              </h5>
              <p className="paragraph">
                <div
                  dangerouslySetInnerHTML={{
                    __html: service.service_description2,
                  }}
                />
              </p>
            </div>

            <div className="col-md-6 mt-4">
              <div>
                <i className={`${service.service_icon3} fs-2`}></i>
              </div>
              <h5 className="fs-5 text-uppercase letter-spacing mb-4 ">
                {service.service_text3}
              </h5>
              <p className="paragraph">
                <div
                  dangerouslySetInnerHTML={{
                    __html: service.service_description3,
                  }}
                />
              </p>
            </div>

            <div className="col-md-6 mt-4">
              <div>
                <i className={`${service.service_icon4} fs-2`}></i>
              </div>
              <h5 className="fs-5 text-uppercase letter-spacing mb-4 ">
                {service.service_text4}
              </h5>
              <p className="paragraph">
                <div
                  dangerouslySetInnerHTML={{
                    __html: service.service_description4,
                  }}
                />
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5 mb-5">
        <div className="container-box py-md-4 ">
          <div className="top-heading">
            <p className="text-orange mb-1 text-uppercase">
              {service.insight_title}
            </p>
            <span className="title-h2 d-block">
              <b>{service.insight_subtitle}</b>
            </span>
          </div>
          <div className="row">
            <div className="col-md-3 insight-card">
              <div className="mt-4">
                <div className="recent-insights-container">
                  <img
                    src={`${Config.imageUrl}/ourservice/${service.insight_image}`}
                    alt=""
                  />
                </div>

                <h5 className="insight">{service.insight_text}</h5>
              </div>
            </div>

            <div className="col-md-3 insight-card">
              <div className="mt-4">
                <div className="recent-insights-container">
                  <img
                    src={`${Config.imageUrl}/ourservice/${service.insight_image2}`}
                    alt=""
                  />
                </div>

                <h5 className="insight">{service.insight_text2}</h5>
              </div>
            </div>

            <div className="col-md-3 insight-card">
              <div className="mt-4">
                <div className="recent-insights-container">
                  <img
                    src={`${Config.imageUrl}/ourservice/${service.insight_image3}`}
                    alt=""
                  />
                </div>

                <h5 className="insight">{service.insight_text3}</h5>
              </div>
            </div>

            <div className="col-md-3 insight-card">
              <div className="mt-4">
                <div className="recent-insights-container">
                  <img
                    src={`${Config.imageUrl}/ourservice/${service.insight_image4}`}
                    alt=""
                  />
                </div>

                <h5 className="insight">{service.insight_text4}</h5>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default ImplementationService;
