import React, { useEffect, useMemo, useState } from "react";
import logo from "../../assets/Images/logo.png";
import { Link, NavLink } from "react-router-dom";
import { Config } from "../../Config";

const Navbar = () => {
  const [stickyNavbar, setStickyNavbar] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, []);

  window.addEventListener("scroll", () => {
    if (window.scrollY > 100) {
      setStickyNavbar(true);
    } else {
      setStickyNavbar(false);
    }
  });

  // mobile navbar links data
  const solutionLinks = useMemo(
    () => [
      {
        label: "Blue Yonder Solutions",
        link: `${Config.appUrl}`,
        innerLinks: [
          {
            label: "Demand Planning",
            link: `${Config.appUrl}solution/blue-yonder-solution/demand-planning`,
          },
          {
            label: "Supply Planning",
            link: `${Config.appUrl}solution/blue-yonder-solution/supply-planning`,
          },
          {
            label: "Production Planning",
            link: `${Config.appUrl}solution/blue-yonder-solution/production-planning`,
          },
          {
            label: "Inventory Optimization",
            link: `${Config.appUrl}solution/blue-yonder-solution/inventory-optimization`,
          },
          {
            label: "Warehouse Management",
            link: `${Config.appUrl}solution/blue-yonder-solution/warehouse-management`,
          },
          {
            label: "Space & Floor Planning",
            link: `${Config.appUrl}solution/blue-yonder-solution/space-floor-planning`,
          },
        ],
      },
      {
        label: "Kinaxis Solutions",
        link: `${Config.appUrl}`,
        innerLinks: [
          {
            label: "Demand Planning",
            link: `${Config.appUrl}solution/kinaxis-solution/demand-planning`,
          },
          {
            label: "Supply Planning",
            link: `${Config.appUrl}solution/kinaxis-solution/supply-planning`,
          },
          {
            label: "Inventory Management",
            link: `${Config.appUrl}solution/kinaxis-solution/inventory-management`,
          },
          {
            label: "Sales and Operations Planning",
            link: `${Config.appUrl}solution/kinaxis-solution/sales-and-operation-planning`,
          },
        ],
      },
      {
        label: "Manhattan WMS Solutions",
        link: `${Config.appUrl}`,
        innerLinks: [
          {
            label: "Manhattan's Active Warehouse Management",
            link: `${Config.appUrl}solution/manhattan-wms/active-warehouse-management`,
          },
          {
            label: "WMoS (Open System)",
            link: `${Config.appUrl}solution/manhattan-wms/wmos-open-system`,
          },
          {
            label: "SCALE WMS",
            link: `${Config.appUrl}solution/manhattan-wms/scale-wms`,
          },
        ],
      },
      {
        label: "SAP IBP",
        link: `${Config.appUrl}`,
        innerLinks: [
          {
            label: "Demand Planning",
            link: `${Config.appUrl}solution/sap-ibp/demand-planning`,
          },
          {
            label: "Supply Planning",
            link: `${Config.appUrl}solution/sap-ibp/supply-planning`,
          },
        ],
      },
    ],
    []
  );

  const servicesLinks = useMemo(
    () => [
      {
        label: "Implementation Services",
        link: `${Config.appUrl}services/implementation-service`,
      },
      {
        label: "Support Services",
        link: `${Config.appUrl}services/support-service`,
      },
      {
        label: "Strategy Services",
        link: `${Config.appUrl}services/strategy-service`,
      },
      {
        label: "Cloud Transformation",
        link: `${Config.appUrl}services/cloud-transformation`,
      },
      {
        label: "Project Planning and Advisory",
        link: `${Config.appUrl}services/project-planning-advisory`,
      },
      {
        label: "Software Selection",
        link: `${Config.appUrl}services/software-selection`,
      },
      {
        label: "Process & System Optimization",
        link: `${Config.appUrl}services/system-optimization`,
      },
    ],
    []
  );

  const industriesLinks = useMemo(
    () => [
      {
        label: "Retail",
        link: `${Config.appUrl}industries/retail`,
      },
      {
        label: "Distribution",
        link: `${Config.appUrl}industries/distribution`,
      },
      {
        label: "Manufacturing",
        link: `${Config.appUrl}industries/manufacturing`,
      },
      {
        label: "Consumer Packaged Goods",
        link: `${Config.appUrl}industries/consumer-Packaged-goods`,
      },
    ],
    []
  );

  // ====================
  return (
    <div>
      <nav className={`pd-navbar ${stickyNavbar ? "skicky-navbar" : ""}`}>
        {/* <div className=""> */}
        <div className="row justify-space-between mx-0">
          <div className="col-lg-4 left-nav ps-md-4 ps-4 pe-md-0 pe-4">
            <div className="d-flex justify-content-between align-items-center">
              <Link
                to={`${Config.appUrl}`}
                className="navbar-brand ps-md-4"
                href="#"
              >
                <img src={logo} alt="Logo" width="180"></img>
              </Link>

              <div className="">
                <i
                  className="bi bi-list fs-1 menu-btn text-white py-1 px-2 bg-dark rounded-circle"
                  onClick={() => setMobileMenu(!mobileMenu)}
                ></i>
              </div>
            </div>

            <div className="shape-triangle d-lg-block d-none"></div>
          </div>
          {/* desktop navbar */}
          <div className="col-lg-8 text-md-end text-center right-nav pe-4">
            <div className="navbar-links  pe-md-4">
              <ul className="navlinks">
                {/* <li><NavLink to={`${Config.appUrl}`}>Solution</NavLink></li> */}
                <li>
                  <NavLink to={`#`}>
                    Solution <i className="bi bi-chevron-down fs-6 ps-1"></i>{" "}
                  </NavLink>
                  <ul className="inner-links text-start shadow">
                    <li>
                      <NavLink to={`#`}>
                        Blue Yonder Solutions
                        <ul className="inner-inner-links">
                          <li>
                            <NavLink
                              to={`${Config.appUrl}solution/blue-yonder-solution/demand-planning`}
                            >
                              {" "}
                              Demand Planning
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to={`${Config.appUrl}solution/blue-yonder-solution/supply-planning`}
                            >
                              Supply Planning
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to={`${Config.appUrl}solution/blue-yonder-solution/production-planning`}
                            >
                              Production Planning
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to={`${Config.appUrl}solution/blue-yonder-solution/inventory-optimization`}
                            >
                              Inventory Optimization
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to={`${Config.appUrl}solution/blue-yonder-solution/warehouse-management`}
                            >
                              Warehouse Management
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to={`${Config.appUrl}solution/blue-yonder-solution/space-floor-planning`}
                            >
                              Space & Floor Planning
                            </NavLink>
                          </li>
                        </ul>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={`#`}>
                        Kinaxis Solutions
                        <ul className="inner-inner-links">
                          <li>
                            <NavLink
                              to={`${Config.appUrl}solution/kinaxis-solution/demand-planning`}
                            >
                              {" "}
                              Demand Planning
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to={`${Config.appUrl}solution/kinaxis-solution/supply-planning`}
                            >
                              Supply Planning
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to={`${Config.appUrl}solution/kinaxis-solution/inventory-management`}
                            >
                              Inventory Management
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to={`${Config.appUrl}solution/kinaxis-solution/sales-and-operation-planning`}
                            >
                              Sales and Operations Planning
                            </NavLink>
                          </li>
                        </ul>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={`#`}>
                        Manhattan Wms Solutions
                        <ul className="inner-inner-links">
                          <li>
                            <NavLink
                              to={`${Config.appUrl}solution/manhattan-wms/active-warehouse-management`}
                            >
                              {" "}
                              Manhattan's Active Warehouse Management
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to={`${Config.appUrl}solution/manhattan-wms/wmos-open-system`}
                            >
                              WMoS (Open System)
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to={`${Config.appUrl}solution/manhattan-wms/scale-wms`}
                            >
                              Scale WMS
                            </NavLink>
                          </li>
                        </ul>
                      </NavLink>
                    </li>

                    <li>
                      <NavLink to={`#`}>
                        SAP IBP
                        <ul className="inner-inner-links">
                          <li>
                            <NavLink
                              to={`${Config.appUrl}solution/sap-ibp/demand-planning`}
                            >
                              {" "}
                              Demand Planning
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to={`${Config.appUrl}solution/sap-ibp/supply-planning`}
                            >
                              Supply Planning
                            </NavLink>
                          </li>
                        </ul>
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li>
                  <NavLink to={`#`}>
                    Our Services{" "}
                    <i className="bi bi-chevron-down fs-6 ps-1"></i>{" "}
                  </NavLink>
                  <ul className="inner-links text-start shadow">
                    <li>
                      <NavLink
                        to={`${Config.appUrl}services/implementation-service`}
                      >
                        Implementation Services
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={`${Config.appUrl}services/support-service`}>
                        Support Services​
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={`${Config.appUrl}services/strategy-service`}>
                        Strategy Services
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={`${Config.appUrl}services/cloud-transformation`}
                      >
                        Cloud Transformation
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={`${Config.appUrl}services/project-planning-advisory`}
                      >
                        Project Planning and Advisory
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={`${Config.appUrl}services/software-selection`}
                      >
                        Software Selection
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={`${Config.appUrl}services/system-optimization`}
                      >
                        Process & System Optimization
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={`${Config.appUrl}services/us-it-staffing-services`}
                      >
                        Us It Staffing Services
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li>
                  <NavLink to={`#`}>
                    Industries <i className="bi bi-chevron-down fs-6 ps-1"></i>{" "}
                  </NavLink>
                  <ul className="inner-links text-start shadow">
                    <li>
                      <NavLink to={`${Config.appUrl}industries/retail`}>
                        Retail{" "}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={`${Config.appUrl}industries/distribution`}>
                        Distribution{" "}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={`${Config.appUrl}industries/manufacturing`}>
                        Manufacturing{" "}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={`${Config.appUrl}industries/consumer-Packaged-goods`}
                      >
                        Consumer Packaged Goods{" "}
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li>
                  <NavLink to={`${Config.appUrl}about-us`}>About Us</NavLink>
                </li>
                <li>
                  <NavLink to={`${Config.appUrl}contact`}>Contact Us</NavLink>
                </li>
              </ul>
            </div>
          </div>

          {/* Mobile menu ============================================================== */}
          <div className={`mobile-menu ${mobileMenu && "showMenu"}`}>
            <ul className="mobile-navlinks ps-0 pt-2">
              <div className="d-flex justify-content-end mb-5">
                s
                {/* <NavLink to={`${Config.appUrl}`}>
                  <img src={logo} alt="" className='ps-3 pt-2' width={145} />
                </NavLink> */}
                <h2
                  onClick={() => setMobileMenu(false)}
                  className="menu-btn text-center px-3 py-2 me-2 mt-2 rounded-circle text-white"
                  style={{ backgroundColor: "#ffffff17" }}
                >
                  &times;
                </h2>
              </div>

              <InnerLinks
                setMobileMenu={setMobileMenu}
                linkData={solutionLinks}
                name="Solution"
              />
              <InnerLinks
                setMobileMenu={setMobileMenu}
                linkData={servicesLinks}
                name="Our Services"
              />
              <InnerLinks
                setMobileMenu={setMobileMenu}
                linkData={industriesLinks}
                name="Industries"
              />

              <li>
                <NavLink
                  onClick={() => setMobileMenu(false)}
                  to={`${Config.appUrl}about-us`}
                >
                  About Us
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={() => setMobileMenu(false)}
                  to={`${Config.appUrl}contact`}
                >
                  Contact Us
                </NavLink>
              </li>
            </ul>
            <div className="paragraph text-white my-5 ps-2">
              <span className="fw-bold">Feel Free to Call Us :</span>
              <a
                href="tel:+91 9013783898"
                className="text-decoration-none text-white px-2 d-block mt-3"
              >
                {" "}
                <i className="bi bi-telephone me-2"></i> +91 9013783898{" "}
              </a>
            </div>
          </div>
        </div>
        {/* </div> */}
      </nav>
    </div>
  );
};

{
  /* For Mobile innerLinks ============================================================== */
}

const InnerLinks = ({ setMobileMenu, linkData, name }) => {
  const [innerLinks, setInnerLinks] = useState(false);

  return (
    <li>
      <button
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setInnerLinks(!innerLinks);
        }}
        className="btn w-100 text-white border-0 d-flex justify-content-between align-items-center py-0"
      >
        {name}
        <span className="ps-4 py-2 bg-transparent text-end border-0 text-white">
          <i className={`bi bi-chevron-${innerLinks ? "up" : "down"} fs-6`}></i>
        </span>
      </button>
      <ul
        className={`mobile-inner-links ps-0 d-block ${
          innerLinks ? "d-block" : "d-none"
        }`}
      >
        {linkData.map((sublink, i) => {
          if (sublink.innerLinks) {
            return (
              <InnerInnerLinks
                key={i}
                innerInnerLinks={sublink.innerLinks}
                setMobileInnerMenu={setMobileMenu}
                name={sublink.label}
              />
            );
          } else {
            return (
              <li key={i}>
                <NavLink onClick={() => setMobileMenu(false)} to={sublink.link}>
                  {sublink.label}
                </NavLink>
              </li>
            );
          }
        })}
      </ul>
    </li>
  );
};

const InnerInnerLinks = ({ innerInnerLinks, setMobileMenu, name }) => {
  const [innerLinks, setInnerLinks] = useState(false);

  return (
    <li>
      <button
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setInnerLinks(!innerLinks);
        }}
        className="btn w-100 text-white border-0 d-flex justify-content-between align-items-center py-0"
      >
        {name}
        <span className="py-2 bg-transparent text-end border-0 text-white">
          <i
            className={`bi bi-chevron-${
              innerLinks ? "down" : "right"
            } text-orange fs-6`}
          ></i>
        </span>
      </button>
      <ul
        className={`mobile-inner-links ps-0 d-block ${
          innerLinks ? "d-block" : "d-none"
        }`}
      >
        {innerInnerLinks.map((sublink, i) => {
          return (
            <li key={i}>
              <NavLink
                onClick={() => setMobileMenu(false)}
                to={sublink.link}
                className="d-flex align-items-center fs-6 text-light"
              >
                <i className="bi bi-chevron-right me-1 text-orange"></i>
                {sublink.label}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </li>
  );
};

export default Navbar;
