import React from 'react'
import { Link } from 'react-router-dom'
import { Config } from '../Config'

const CtaFooter = () => {
    return (
        <section >
            <div className="container-box pt-md-5" style={{ transform: 'translateY(40px)' }}>
                <div className='px-md-5'>
                    <div className='d-md-flex justify-content-between align-items-center shadow-lg bg-dark p-4 px-md-5' >
                        <div className='col-md-7'>
                            <h2 className='mb-0  text-white'>Request a Free Supply Chain Consultation.</h2>
                        </div>

                        <div className='mt-md-0 mt-5 mb-md-0 mb-4'>
                            <Link to={`${Config.appUrl}contact`} className='style-btn shadow'>
                                <span>{" > "}</span> <div className='d-inline'> Let's Talk with <b>plan4ai</b></div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CtaFooter