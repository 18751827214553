import React, { useState, useEffect } from "react";
import Navbar from "../Home/Navbar";
import { Link, useParams } from "react-router-dom";
import Footer from "../Home/Footer";
import swal from "sweetalert";
import { Config } from "../../Config";
import Loader from "../../Components/Loader";
import axios from "axios";

const BlogDetails = () => {
  const [loading, setLoading] = useState(true);
  const { slug } = useParams();
  const [blog, setBlog] = useState({});
  const [latest_post, setLatestPost] = useState([]);
  const [message, setMessage] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [latestBlogs, setLatestBlogs] = useState([]);
  const [errors, setErrors] = useState({
    fullName: "",
    message: "",
    email: "",
    website: "",
  });
  document.title = "Blog Details | Plan4AI";

  const loadData = () => {
    setLoading(true);
    try {
      axios
        .get(`${Config.apiUrl}/blogdetail/${slug}`, {
          headers: {
            //'Authorization': 'Bearer ',
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setLoading(false);
          const data = response?.data;
          setBlog(data?.blog);
          setLatestPost(data?.latest_post);
          setLatestBlogs(data?.latest_post);
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      console.log("There was a problem fetching the data:", error);
    }
  };

  useEffect(() => {
    loadData();
    window.scrollTo({
      top: 0,
      left: document.body.scrollWidth,
      behavior: "smooth", // Optional: Adds smooth scrolling behavior
    });
  }, [slug]);

  const handleSubmitData = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const formData = new FormData();
      formData.append("name", fullName);
      formData.append("message", message);
      formData.append("email", email);
      formData.append("website", website);
      setLoading(true);
      axios
        .post(`${Config.apiUrl}/blog_comment`, formData, {
          headers: {
            //'Authorization': 'Bearer ',
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          //status: 1, company: {…}, message: "Message Succesfully sent..."
          if (response.data.message === "Message Succesfully sent...") {
            swal("Thank You!", "Your Comment has been added successfully", {
              icon: "success",
            });
            setMessage("");
            setFullName("");
            setEmail("");
            setWebsite("");
          } else {
            swal("Error!", "Something went Wrong! Please Try Again.", {
              icon: "error",
            });
          }
        })
        .catch((error) => {
          console.error("There was a problem fetching the data:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    const filteredBlogs = latestBlogs.filter((blog) =>
      blog.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setLatestPost(filteredBlogs);
  };
  const validateForm = () => {
    const newErrors = {};

    if (!fullName) {
      newErrors.fullName = "Name is required";
    }
    if (!email) {
      newErrors.email = "Email is required";
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      newErrors.email = "Invalid email format";
    }
    if (!website) {
      newErrors.website = "Website is required";
    }
    if (!message) {
      newErrors.message = "Your Query is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return (
    <>
      {loading && <Loader />}
      <Navbar />

      <section
        className="page-header py-5"
        style={{
          backgroundImage:
            "linear-gradient(#ffffff56, #00000091 60%), url('https://images.unsplash.com/photo-1611746351408-c0a1346be8e8?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
          backgroundPosition: "bottom",
          backgroundPosition: "47% 58%",
        }}
      >
        <div className="container-box py-4">
          <div className="text-white">
            <h1 className="fw-bold display-5">{blog?.title}</h1>
            <div className="line"></div>
            <p className="mt-4 header-path-link">
              <Link
                to={`${Config.appUrl}`}
                className="text-orange fw-bold text-decoration-none"
              >
                {" "}
                Home
              </Link>
              <i className="bi bi-arrow-right mx-3"></i>
              Blog Details
            </p>
          </div>
        </div>
      </section>

      <section className="py-5" style={{ backgroundColor: "#f2f5f7" }}>
        <div className="container-box py-md-4">
          <div className="row">
            <div className="col-md-4">
              <div className="bg-white shadow p-4">
                <div className="position-relative">
                  <input
                    value={searchTerm}
                    onChange={handleSearch}
                    type="search"
                    placeholder="Search for blogs.."
                    className="w-100 py-2 px-5 searchbar"
                  />
                  <i className="bi bi-search search-icon"></i>
                </div>
              </div>

              <div className="bg-white shadow p-4 mt-4">
                <h5>Recent Blog Posts</h5>

                <div className="mt-4">
                  {latest_post.map((post, index) => {
                    const postDate = new Date(post.updated_at);

                    const formattedDate = postDate.toLocaleDateString("en-US", {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    });

                    return (
                      <div key={index} className="recent-blog-card mb-3">
                        <div>
                          <img
                            src={`${Config.imageUrl}/blogs/${post.main_image}`}
                            alt=""
                          />
                        </div>
                        <div className="ms-3">
                          <Link
                            to={`${Config.appUrl}blog-details/${post.slug}`}
                            className="nav-link"
                          >
                            <h6 className="mb-1">{post.title}</h6>
                          </Link>
                          <p>{formattedDate}</p>
                        </div>
                      </div>
                    );
                  })}
                  {latest_post.length == 0 && (
                    <div className="d-flex justify-content-center text-muted">
                      No Blog Found
                    </div>
                  )}
                </div>
              </div>

              <div className="bg-white shadow p-4 mt-4">
                <h5>Contact For More</h5>

                <div className="mt-4">
                  <div className="d-flex align-items-center">
                    <i className="bi bi-telephone-inbound fs-4"></i>
                    <a
                      href="tell:+91 9013783898"
                      className="paragraph text-decoration-none ps-4"
                    >
                      +91 9013783898
                    </a>
                  </div>

                  <div className="d-flex align-items-center mt-3">
                    <i className="bi bi-envelope-at fs-4"></i>
                    <a
                      href="mailto:admin@plan4ai.com"
                      className="text-decoration-none paragraph ps-4"
                    >
                      {" "}
                      admin@plan4ai.com
                    </a>
                  </div>

                  <div className="d-flex align-items-center mt-3">
                    <i className="bi bi-geo-alt fs-4"></i>
                    <p className="mb-0 paragraph ps-4">
                      3000 Crossings Blvd Lancaster, PA 17601
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-8">
              <div className="bg-white shadow p-4">
                <img
                  src={`${Config.imageUrl}/blogs/${blog?.main_image}`}
                  className="w-100 blog-img"
                  alt=""
                />

                <div className="mt-4">
                  <div>
                    <span dangerouslySetInnerHTML={{ __html: blog?.content }} />
                  </div>

                  <p className="paragraph">
                    <div
                      dangerouslySetInnerHTML={{ __html: blog?.description }}
                    />
                  </p>

                  <form className="mt-4" onSubmit={handleSubmitData}>
                    <h5>Leave a comment</h5>
                    <div className="row">
                      <div className="col-12 mb-3">
                        <textarea
                          className="form-control py-2 rounded-0"
                          placeholder="Add Your Comment here.."
                          rows="6"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        ></textarea>
                        {errors.message && (
                          <span className="text-danger">
                            {errors.message}
                          </span>
                        )}
                      </div>
                      <div className="col-md-4 mb-3">
                        <input
                          type="text"
                          className="form-control py-2 rounded-0"
                          placeholder="Full Name"
                          value={fullName}
                          onChange={(e) => setFullName(e.target.value)}
                        />{" "}
                        {errors.fullName && (
                          <span className="text-danger">
                            {errors.fullName}
                          </span>
                        )}
                      </div>
                      <div className="col-md-4 mb-3">
                        <input
                          type="text"
                          className="form-control py-2 rounded-0"
                          placeholder="Email Address"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                           {errors.email && (
                          <span className="text-danger">
                            {errors.email}
                          </span>
                        )}
                      </div>
                      <div className="col-md-4 mb-3">
                        <input
                          type="text"
                          className="form-control py-2 rounded-0"
                          placeholder="Website"
                          value={website}
                          onChange={(e) => setWebsite(e.target.value)}
                        />
                           {errors.website && (
                          <span className="text-danger">
                            {errors.website}
                          </span>
                        )}
                      </div>

                      <div className="col-12 mt-4 mb-5">
                        <button
                          type="submit"
                          className="style-btn shadow border-0"
                        >
                          <span>{" > "}</span>{" "}
                          <div className="d-inline"> Leave Comment Now</div>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default BlogDetails;
