import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Index from "./Pages/Home/Index";
import Contact from "./Pages/Contact/Contact";
import { useState } from "react";
import KinaxinSolution from "./Pages/Solution/KinaxinSolution";
import ImplementationService from "./Pages/Services/ImplementationService";
import AboutUs from "./Pages/About/AboutUs";
import ManhattanWms from "./Pages/Solution/ManhattanWms";
import Blogs from "./Pages/Blogs/Blogs";
import Retail from "./Pages/Industries/Retail";
import BlogDetails from "./Pages/Blogs/BlogDetails";
import { Config } from "./Config";
import SapIbp from "./Pages/Solution/SapIbp";

import Kinaxis from "./Pages/Solution/Kinaxin Solution/Kinaxis";
import Manhattan from "./Pages/Solution/Manhattan Wms/Manhattan";
import Blueyounder from "./Pages/Solution/Blue Younder Solution/Blueyounder";
import SapLbp from "./Pages/Solution/Sab Ibp/SapLbp";
import PrivacyPolicy from "./Pages/Privacy-policy/PrivacyPolicy";

function App() {
  const [stickySocial, setStickySocial] = useState(false);

  window.addEventListener("scroll", () => {
    if (window.scrollY > 500) {
      setStickySocial(true);
    } else {
      setStickySocial(false);
    }
  });

  return (
    <div className="">
      <Router>
        <Routes>
          <Route path={`${Config.appUrl}`} Component={Index} />

          <Route path={`${Config.appUrl}blogs`} Component={Blogs} />
          <Route
            path={`${Config.appUrl}blog-details/:slug`}
            Component={BlogDetails}
          />
          {/* solutions pages */}
          <Route
            path={`${Config.appUrl}solution/kinaxis-solution`}
            Component={KinaxinSolution}
          />
          <Route
            path={`${Config.appUrl}solution/manhattan-wms`}
            Component={ManhattanWms}
          />
          <Route path={`${Config.appUrl}solution/sap-ibp`} Component={SapIbp} />

          <Route
            path={`${Config.appUrl}solution/blue-yonder-solution/:blueyonderSelected`}
            Component={Blueyounder}
          />

          <Route
            path={`${Config.appUrl}solution/kinaxis-solution/:kinaxisSelected`}
            Component={Kinaxis}
          />

          <Route
            path={`${Config.appUrl}solution/manhattan-wms/:manhattanSelected`}
            Component={Manhattan}
          />

          <Route
            path={`${Config.appUrl}solution/sap-ibp/:saplbpSelected`}
            Component={SapLbp}
          />

          {/* solutions pages */}

          {/* our Services */}
          <Route
            path={`${Config.appUrl}services/:serviceSelected`}
            Component={ImplementationService}
          />
          {/* ............ */}

          {/* Industries */}
          <Route
            path={`${Config.appUrl}industries/:industrySelected`}
            Component={Retail}
          />
          {/* .......... */}

          <Route path={`${Config.appUrl}about-us`} Component={AboutUs} />

          <Route path={`${Config.appUrl}contact`} Component={Contact} />

          <Route
            path={`${Config.appUrl}privacy-policy`}
            Component={PrivacyPolicy}
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
