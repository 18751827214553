import React from "react";
import { Link } from "react-router-dom";
import { Config } from "../Config";

const BlogCard = ({ blog }) => {
  return (
    <div className="col-md-4 mb-5">
      <div className="service-card blog-card">
        <div>
          <img
            src={`${Config.imageUrl}/blogs/${blog.image}`}
            className="w-100"
            alt=""
          />
          <div className="hover-box">{blog.date}</div>
          <Link
            to={`${Config.appUrl}blog-details/${blog.slug}`}
            className="read-btn"
          >
            {" "}
            Read Full Blog{" "}
            <i className="bi bi-chevron-double-right arrow-animation"></i>{" "}
          </Link>
        </div>

        <div className="service-card-content mt-4">
          <h5>{blog.title} </h5>
          <div
            dangerouslySetInnerHTML={{
              __html: blog.desc?.slice(0, 80) + " ...",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
