import React, { useEffect, useRef, useState } from "react";
import Navbar from "./Navbar";
import "../../assets/css/Home.css";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";

import Slider from "react-slick";
// slick slider
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// partners

import Footer from "./Footer";
import { Link } from "react-router-dom";
import BlogCard from "../../Components/BlogCard";
import CtaFooter from "../../Components/CtaFooter";
import { Config } from "../../Config";
import Loader from "../../Components/Loader";
import { RotatingLines } from "react-loader-spinner";

const Index = () => {
  useEffect(() => {
    AOS.init({
      offset: 100, // offset (in px) from the original trigger point
      duration: 800, // values from 0 to 3000, with step 50ms
      easing: "ease-out",
      once: false,
    });
  }, []);

  const [loading, setLoading] = useState(true);
  const [bannerSection, setBannerSection] = useState({});
  const [blogs, setBlogs] = useState([]);
  const [blogSection, setBlogSection] = useState({});
  const [homeintro, setHomeInto] = useState({});
  const [service, setService] = useState([]);
  const [serviceSection, setServiceSection] = useState({});

  const sliderRef = useRef();

  const blogsSettings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 3,
    swipeToSlide: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 985,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  const blogData = blogs.slice(0, 3).map((blog) => ({
    id: blog.id,
    image: blog.main_image,
    date: new Date(blog.updated_at).toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    }),
    title: blog.title,
    desc: blog.description,
    slug: blog.slug,
  }));

  document.title = "Home | Plan4AI";

  useEffect(() => {
    axios
      .get(`${Config.apiUrl}/homepage`, {
        headers: {
          //'Authorization': 'Bearer ',
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const data = response.data.home_page;
        setHomeInto(data.home_intro);
        setBannerSection(data.banner_section);
        setServiceSection(data.service_section);
        setBlogSection(data.blog_section);
        setService(data.service);
        setBlogs(data.blog);
      })
      .catch((error) => {
        console.error("There was a problem fetching the data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div>
      {loading && <Loader />}

      <Navbar />

      {/* Banner section */}

      <div
        className="main-banner d-flex justify-content-center align-items-center"
        style={{
          backgroundImage: `linear-gradient(#aa30203e, #000000ba 60%), url(${Config.imageUrl}/${bannerSection.hero_bg})`,
        }}
      >
        <div className="main-banner-content">
          <h1>{bannerSection.hero_section_title}</h1>
          <p className="paragraph fs-4 text-white mt-4">
            {bannerSection.hero_section_text}
          </p>

          <div className="mt-5">
            <Link to={`${Config.appUrl}about-us`} className="style-btn">
              <span>{" > "}</span>{" "}
              <div className="d-inline">
                {bannerSection.hero_section_button_text}
              </div>
            </Link>
          </div>
        </div>
      </div>

      {/* <div className="py-5 bg-style-img">
        <div className="container-box">
          <div className="d-md-flex justify-content-between align-items-center">
            <div>
              <h2 className="text-dark-blue fw-bold text-capitalize">
                {homeintro.home_text}
              </h2>
              <p className="paragraph">{homeintro.home_description}</p>
            </div>

            <div className="col-md-3 text-md-end mt-md-0 mt-5">
              <Link
                to={`${Config.appUrl}about-us`}
                className="style-btn shadow"
              >
                <span>{" > "}</span>{" "}
                <div className="d-inline"> {homeintro.home_button_text}</div>
              </Link>
            </div>
          </div>
        </div>
      </div> */}

      <div className="py-5">
        <div className="container-box py-md-5">
          <div className="top-heading mb-5 px-mb-3">
            <p className="text-orange mb-1 text-uppercase">
              {serviceSection.service_section_title}
            </p>
            <span className="title-h2 d-block text-dark-blue">
              <b>{serviceSection.service_section_subtitle}</b>
            </span>

            <p className="paragraph mt-3">
              <div
                dangerouslySetInnerHTML={{
                  __html: serviceSection.service_section_description,
                }}
              />
            </p>
          </div>

          <div className="row justify-content-center">
            {service.slice(0,4).map((service) => (
              <div
                key={service.id}
                className="col-md-6 px-4"
                data-aos="fade-top"
                data-aos-delay="100"
              >
                <div className="service-card-1 mt-4">
                  <img
                    src={`${Config.imageUrl}/ourservice/${service.main_image}`}
                    className="service-img"
                    alt=""
                  />
                  <div className="pt-md-4 service-data">
                    <h5>{service.page_name}</h5>
                    <div
                      dangerouslySetInnerHTML={{ __html: service.description }}
                    ></div>

                    <div className="mt-5">
                      <Link
                        to={`${Config.appUrl}services/${service.slug}`}
                        className="style-btn shadow"
                      >
                        <span className="text-dark">{" > "}</span>{" "}
                        <div className="d-inline"> know more</div>
                      </Link>
                    </div>
                    {/* <a href="#"> Know More <i className="bi bi-chevron-double-right arrow-animation"></i> </ a> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* blogs */}
      <div className="py-5 bg-dots">
        <div className="container-box py-md-4 ">
          <div className="d-md-flex justify-content-between align-items-center">
            <div className="top-heading">
              <p className="text-orange mb-1 text-uppercase">
                {blogSection.blog_section_title}
              </p>
              <span className="title-h2 d-block">
                <b>{blogSection.blog_section_subtitle}</b>
              </span>
            </div>

            <div className="mt-md-0 mt-5">
              <Link to={`${Config.appUrl}blogs`} className="style-btn">
                <span>{" > "}</span>{" "}
                <div className="d-inline">{blogSection.blog_section_btn}</div>
              </Link>
            </div>
          </div>

          <div className="mt-5">
            <div className="row ">
              {/* <Slider ref={sliderRef} {...blogsSettings}> */}
              {blogData.map((blog, i) => {
                return <BlogCard key={i} blog={blog} />;
              })}
              {/* </Slider> */}
            </div>
          </div>
        </div>
      </div>

      <CtaFooter />

      <Footer />
    </div>
  );
};

export default Index;
