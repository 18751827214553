import React, { useState, useEffect } from "react";
import Navbar from "../Home/Navbar";
import { Link, useParams } from "react-router-dom";
import Footer from "../Home/Footer";
import { Config } from "../../Config";
import axios from "axios";
import { RotatingLines } from "react-loader-spinner";
import Loader from "../../Components/Loader";

const Retail = () => {
  const { industrySelected } = useParams();

  const [loading, setLoading] = useState(true);
  const [industry, setIndustry] = useState({});

  const loadData = () => {
    setLoading(true);
    try {
      axios
        .get(`${Config.apiUrl}/industries/${industrySelected}`, {
          headers: {
            //'Authorization': 'Bearer ',
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setLoading(false);
          setIndustry(response.data.industries);
        });
    } catch (error) {
      console.error("There was a problem fetching the data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
    window.scrollTo({
      top: 0,
      left: document.body.scrollWidth,
      behavior: "smooth", // Optional: Adds smooth scrolling behavior
    });
  }, [industrySelected]);

  document.title = `${industry.page_name} | Plan4AI`;
  return (
    <>
      {loading && <Loader />}

      <Navbar />

      <section
        className="page-header py-5"
        style={{
          backgroundImage:
            "linear-gradient(rgba(255, 255, 255, 0.337), rgba(0, 0, 0, 0.6) 0%), url('https://images.unsplash.com/photo-1556740714-a8395b3bf30f?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
          backgroundPosition: "bottom",
        }}
      >
        <div className="container-box py-4">
          <div className="text-white">
            <h1 className="fw-bold display-5">{industry.page_name}</h1>
            <div className="line"></div>
            <p className="mt-4 header-path-link">
              <Link
                to={`${Config.appUrl}`}
                className="text-orange fw-bold text-decoration-none"
              >
                {" "}
                Home
              </Link>
              <i className="bi bi-arrow-right mx-3"></i>
              {industry.page_name}
            </p>
          </div>
        </div>
      </section>

      <section className="py-5 industries">
        <div className="container-box py-md-5">
          <div className="row about-section px-md-5">
            <div className="col-12">
              <div>
                <h2 className="text-dark-blue fw-bold text-capitalize">
                  {industry.title}
                </h2>

                <p className="paragraph lh-lg mt-4">
                  <div
                    dangerouslySetInnerHTML={{ __html: industry.description }}
                  />
                </p>

                <div className="mt-4">
                  {/* accordian */}
                  <div
                    className="accordion accordion-flush"
                    id="accordionFlushExample"
                  >
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne"
                          aria-expanded="false"
                          aria-controls="flush-collapseOne"
                        >
                          <i className={`${industry.point_icon} me-3`}></i>{" "}
                          {industry.point_title}
                        </button>
                      </h2>
                      <div
                        id="flush-collapseOne"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p className="paragraph pe-md-5">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: industry.point_description,
                              }}
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwo"
                          aria-expanded="false"
                          aria-controls="flush-collapseTwo"
                        >
                          <i className={`${industry.point_icon2} me-3`}></i>{" "}
                          {industry.point_title2}
                        </button>
                      </h2>
                      <div
                        id="flush-collapseTwo"
                        className="accordion-collapse collapse "
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p className="paragraph pe-md-5">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: industry.point_description2,
                              }}
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseth-three"
                          aria-expanded="false"
                          aria-controls="flush-collapseThree"
                        >
                          <i className={`${industry.point_icon3} me-3`}></i>{" "}
                          {industry.point_title3}
                        </button>
                      </h2>
                      <div
                        id="flush-collapseth-three"
                        className="accordion-collapse collapse "
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p className="paragraph pe-md-5">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: industry.point_description3,
                              }}
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseth-Four"
                          aria-expanded="false"
                          aria-controls="flush-collapseFour"
                        >
                          <i className={`${industry.point_icon4} me-3`}></i>{" "}
                          {industry.point_title4}
                        </button>
                      </h2>
                      <div
                        id="flush-collapseth-Four"
                        className="accordion-collapse collapse "
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p className="paragraph pe-md-5">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: industry.point_description4,
                              }}
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5 bg-dark">
        <div className="container-box py-md-5">
          <div className="row align-items-center px-md-5">
            <div className="col-md-4 d-md-block d-none">
              <img
                src={`${Config.imageUrl}/industries/${industry.testimonial_main_img}`}
                className="w-100 p-1 border border-3 border-secondary"
                style={{ width: "200px" }}
                alt=""
              />
            </div>

            <div className="col-md-8">
              <div className="ps-md-5">
                <div className="d-flex align-items-center">
                  {/* <div>
                    <img
                      src={`${Config.imageUrl}/industries/${industry.testimonial_image}`}
                      alt=""
                      style={{ height: "80px", width: "80px" }}
                      className="rounded-circle object-fit-cover"
                    />
                  </div> */}
                  <div
                  // className="ms-4"
                  >
                    <h5 className="text-orange">{industry.testimonial_name}</h5>
                    <h5 className="text-light fs-6">
                      {industry.testimonial_position}
                    </h5>
                  </div>
                </div>

                <p className="paragraph text-light fs-4 lh-lg mt-5">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: industry.testimonial_description,
                    }}
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5 mb-5">
        <div className="container-box py-md-4 ">
          <div className="top-heading">
            <p className="text-orange mb-1 text-uppercase">
              {industry.insight_title}
            </p>
            <span className="title-h2 d-block">
              <b>{industry.insight_subtitle}</b>
            </span>
          </div>
          <div className="row">
            <div className="col-md-3 insight-card">
              <div className="mt-4">
                <div className="recent-insights-container">
                  <img
                    src={`${Config.imageUrl}/industries/${industry.insight_image}`}
                    alt=""
                  />
                </div>

                <h5 className="insight">{industry.insight_text}</h5>
              </div>
            </div>

            <div className="col-md-3 insight-card">
              <div className="mt-4">
                <div className="recent-insights-container">
                  <img
                    src={`${Config.imageUrl}/industries/${industry.insight_image2}`}
                    alt=""
                  />
                </div>

                <h5 className="insight">{industry.insight_text2}</h5>
              </div>
            </div>

            <div className="col-md-3 insight-card">
              <div className="mt-4">
                <div className="recent-insights-container">
                  <img
                    src={`${Config.imageUrl}/industries/${industry.insight_image3}`}
                    alt=""
                  />
                </div>

                <h5 className="insight">{industry.insight_text3}</h5>
              </div>
            </div>

            <div className="col-md-3 insight-card">
              <div className="mt-4">
                <div className="recent-insights-container">
                  <img
                    src={`${Config.imageUrl}/industries/${industry.insight_image4}`}
                    alt=""
                  />
                </div>

                <h5 className="insight">{industry.insight_text4}</h5>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Retail;
