import React, { useState, useEffect } from "react";
import Navbar from "../Home/Navbar";
import { Link } from "react-router-dom";
import Footer from "../Home/Footer";
import swal from "sweetalert";
import BlogCard from "../../Components/BlogCard";
import CtaFooter from "../../Components/CtaFooter";
import { Config } from "../../Config";
import Loader from "../../Components/Loader";
import axios from "axios";

const Blogs = () => {
  const [loading, setLoading] = useState(false);
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    axios
      .get(`${Config.apiUrl}/blog`, {
        headers: {
          //'Authorization': 'Bearer ',
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const data = response.data.blog_page;
        setBlogs(data.blog);
      })
      .catch((error) => {
        console.error("There was a problem fetching the data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  document.title = "Blogs | Plan4AI";

  const blogsData = blogs.map((blog) => ({
    id: blog.id,
    image: blog.main_image,
    date: new Date(blog.updated_at).toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    }),
    title: blog.title,
    desc: blog.description,
    slug: blog.slug,
  }));

  const blogData = [
    {
      image:
        "https://images.unsplash.com/photo-1578575437130-527eed3abbec?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      date: "November 1, 2023",
      title: "Navigating the Ethical Landscape of AI: Challenges and Solutions",
      desc: "Glass is one of the oldest and most widely used packaging materials. However, is glass environmental.",
    },
    {
      image:
        "https://plus.unsplash.com/premium_photo-1663011354048-a81a907effd2?q=80&w=1471&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      date: "November 1, 2023",
      title: "Advancements in Natural Language Processing: Transforming",
      desc: "The quick answer is yes! Glass is a highly sustainable material with several advantages over other.",
    },
    {
      image:
        "https://images.unsplash.com/photo-1532635042-a6f6ad4745f9?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      date: "November 1, 2023",
      title: "Demystifying Machine Learning: Guide to Understanding Algorithms",
      desc: "A company that manufactures glass bottles for beverages will have a smaller environmental impact.",
    },
    {
      image:
        "https://plus.unsplash.com/premium_photo-1663054378169-8ffea2e11c42?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8YWl8ZW58MHx8MHx8fDA%3D",
      date: "November 1, 2023",
      title: "Natural Advancements in Language: Challenges and Solutions",
      desc: "Glass is one of the oldest and most widely used packaging materials. However, is glass environmental.",
    },
    {
      image:
        "https://images.pexels.com/photos/6153354/pexels-photo-6153354.jpeg?auto=compress&cs=tinysrgb&w=600",
      date: "November 1, 2023",
      title: "Advancements in Natural Language Processing: Transforming",
      desc: "The quick answer is yes! Glass is a highly sustainable material with several advantages over other.",
    },
    {
      image:
        "https://images.unsplash.com/photo-1677756119517-756a188d2d94?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8YWl8ZW58MHx8MHx8fDA%3D",
      date: "November 1, 2023",
      title: "Demystifying Machine Learning: Guide to Understanding Algorithms",
      desc: "A company that manufactures glass bottles for beverages will have a smaller environmental impact.",
    },
  ];

  return (
    <>
      {loading && <Loader />}
      <Navbar />

      <section
        className="page-header py-5"
        style={{
          backgroundImage:
            "linear-gradient(#ffffff56, #00000091 60%), url('https://images.unsplash.com/photo-1611746351408-c0a1346be8e8?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
          backgroundPosition: "bottom",
        }}
      >
        <div className="container-box py-4">
          <div className="text-white">
            <h1 className="fw-bold display-5">Our Blogs </h1>
            <div className="line"></div>
            <p className="mt-4 header-path-link">
              <Link
                to={`${Config.appUrl}`}
                className="text-orange fw-bold text-decoration-none"
              >
                {" "}
                Home
              </Link>
              <i className="bi bi-arrow-right mx-3"></i>
              Our Blogs
            </p>
          </div>
        </div>
      </section>

      <section className="pt-5">
        <div className="container-box pt-md-4">
          <div className="row">
            {blogsData.map((blog, i) => {
              return <BlogCard key={i} blog={blog} />;
            })}
          </div>
        </div>
      </section>

      <CtaFooter />

      <Footer />
    </>
  );
};

export default Blogs;
