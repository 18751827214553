import React from "react";
import Navbar from "../Home/Navbar";
import { Link } from "react-router-dom";
import Footer from "../Home/Footer";
import CtaFooter from "../../Components/CtaFooter";
import { Config } from "../../Config";

const SapIbp = () => {
  document.title = "SAP IBP | plan4ai";

  return (
    <>
      <Navbar />

      <section
        className="page-header py-5"
        style={{
          backgroundImage:
            "linear-gradient(rgba(255, 255, 255, 0.337), rgba(0, 0, 0, 0.57) 0%), url('https://images.unsplash.com/photo-1706007496099-a3e4fea6a835?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
        }}
      >
        <div className="container-box py-4">
          <div className="text-white">
            <h1 className="fw-bold display-5">SAP IBP </h1>
            <div className="line"></div>
            <p className="mt-4 header-path-link">
              <Link
                to={`${Config.appUrl}`}
                className="text-orange fw-bold text-decoration-none"
              >
                {" "}
                Home
              </Link>
              <i className="bi bi-arrow-right mx-3"></i>
              Solution
              <i className="bi bi-arrow-right mx-3"></i>
              SAP IBP
            </p>
          </div>
        </div>
      </section>

      <section className="py-5">
        <div className="container-box py-md-4">
          <div className="top-heading mb-4">
            <p className="text-orange mb-1 text-uppercase">Our work</p>
            <span className="title-h2 d-block text-dark-blue">
              <b>What We Supposed to do?</b>
            </span>

            <p className="paragraph mt-3">
              Welcome to SAP IBP Solutions. Our integrated business planning
              platform enables businesses to align planning processes across the
              entire organization, driving agility, efficiency, and innovation
            </p>
          </div>

          <h4 className="text-secondary fw-bold text-capitalize">
            Our mission is to provide leadership and expertise to you in the
            following areas:
          </h4>

          <div className="mt-4">
            <div className="areas-box">
              SAP IBP System Implementation & Upgrade
            </div>
            <div className="areas-box">SAP IBP Software Sales</div>
            <div className="areas-box">SC Planning as a Service (PaaS)</div>
            <div className="areas-box">Planogram Production & Maintenance</div>
            <div className="areas-box">Vendor Software Selection</div>
            <div className="areas-box">Business Process Design</div>
            <div className="areas-box">Testing & QA</div>
            <div className="areas-box">Program/Project Management</div>

            <div className="areas-box">Custom Training</div>
            <div className="areas-box">Supply Chain Management Diagnostic</div>
            <div className="areas-box">MP&A Diagnostic</div>
          </div>
        </div>
      </section>

      <section className="py-5 bg-style-img">
        <div className="container-box py-md-5">
          <div className="row">
            <div className="col-md-6 pe-md-5">
              <img
                src="https://media.istockphoto.com/id/1615776926/photo/ai-ethics-and-legal-concepts-artificial-intelligence-law-and-online-technology-of-legal.webp?b=1&s=170667a&w=0&k=20&c=i3RMTYYE4XQRvH_PtsOxhVkqBd5b46t7NFAVispN-bg="
                alt=""
                className="w-100 p-1 border border-4"
              />
            </div>

            <div className="col-md-6 mt-md-3 mt-4">
              <span className="title-h2 d-block text-dark-blue">
                <b>We Know SAP IBP</b>
              </span>
              <div className="line mt-3 bg-orange"></div>

              <div className="mt-4">
                <p className="paragraph">
                  <b>Plan4ai</b> has over 20 years of experience delivering
                  implementations, upgrades, and integrations of SAP IBP
                  software applications within the supply chain, merchandising,
                  and planning domains. With more than 300 successful client
                  engagements, we help retailers and manufacturers realize the
                  value and ROI from their SAP IBP applications.
                </p>

                <div className="mt-5">
                  <Link
                    to={`${Config.appUrl}about-us`}
                    className="style-btn shadow"
                  >
                    <span>{" > "}</span>{" "}
                    <div className="d-inline">
                      {" "}
                      Know More about <b>plan4ai</b>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5">
        <div className="container-box py-md-5">
          <div className="top-heading mb-4">
            <p className="text-orange mb-1 text-uppercase">Connect With Us</p>
            <span className="title-h2 d-block text-dark-blue">
              <b>SAP IBP we specialize in: </b>
            </span>
          </div>

          <h4 className="text-secondary fw-bold text-capitalize">
            Supply Chain Planning:
          </h4>

          <div className="row align-items-center">
            <div className="col-md-7">
              <div className="mt-4">
                <div className="d-flex align-items-center mb-3">
                  <i className="bi bi-check fs-1 text-orange"></i>
                  <div className="ms-4">
                    <strong>
                      SAP IBP Supply Chain Planning & Optimization
                    </strong>
                    <p>
                      ( Demand, Fulfillment, Supply, Production & Scheduling,
                      and S&OP )
                    </p>
                  </div>
                </div>

                <div className="d-flex align-items-center mb-3">
                  <i className="bi bi-check fs-1 text-orange"></i>
                  <div className="ms-4">
                    <strong>SAP IBP Merchandise and Assortment Planning</strong>
                    <p>( Enterprise Planning, Assortment, and Allocation )</p>
                  </div>
                </div>

                <div className="d-flex align-items-center mb-3">
                  <i className="bi bi-check fs-1 text-orange"></i>
                  <div className="ms-4">
                    <strong>SAP IBP Space & Category Management</strong>
                    <p>
                      ( CKB, Floor Planning, Space Planning, Space Automation,
                      Planogram Generator )
                    </p>
                  </div>
                </div>

                <div className="d-flex align-items-center mb-3">
                  <i className="bi bi-check fs-1 text-orange"></i>
                  <div className="ms-4">
                    <strong>SAP IBP Store Operations</strong>
                    <p>( Enterprise Store Operations, Workforce Management )</p>
                  </div>
                </div>

                <div className="d-flex align-items-center">
                  <i className="bi bi-check fs-1 text-orange"></i>
                  <div className="ms-4">
                    <strong>
                      Luminate Solutions and Future Roadmap Enablement
                    </strong>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-5 ps-md-5 mt-md-0 mt-5 text-md-start text-center">
              <span className="bordered-text">
                We Can Deliver a <span className="orange-border">SAP IBP </span>{" "}
                in 15+ Weeks
              </span>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5 contact-bg">
        <div className="py-md-5 container-box">
          <div className=" cta-section text-center">
            <h2>Flexible Engagement Models</h2>
            <p className="paragraph text-white">
              A single delivery model can be constraining, which is why we offer
              a variety of ways to engage our experts, allowing you to select
              the option that best fits your needs. Whether you need a single
              pair of hands or an entire team, we’re here to help.
            </p>

            <div className="mt-5">
              <Link to={`${Config.appUrl}contact`} className="style-btn shadow">
                <span>{" > "}</span>{" "}
                <div className="d-inline">
                  {" "}
                  Contact With <b>plan4ai</b>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5 bg-style-img">
        <div className="container-box py-md-5">
          <div className="row">
            <div className="col-md-6 pe-md-5">
              <img
                src="https://images.unsplash.com/photo-1455165814004-1126a7199f9b?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8aW5kdXN0cnl8ZW58MHx8MHx8fDA%3D"
                alt=""
                className="w-100 p-1 border border-4"
              />
            </div>

            <div className="col-md-6 mt-4">
              <span className="title-h2 d-block text-dark-blue">
                <b>Project Work</b>
              </span>
              <div className="line mt-3 bg-orange"></div>

              <div className="mt-4">
                <p className="paragraph lh-lg">
                  Most engagements involve project teams deploying
                  recommendations or implementing solutions across process and
                  technology. We’ve worked with some of the largest and
                  best-known brands in the world; we’ll put that experience to
                  work for you. We partner with you and deliver projects on
                  time, within budget, and to specification.
                </p>
              </div>
            </div>
          </div>

          <div className="row mt-5 pt-md-4">
            <div className="col-md-6 pe-md-5">
              <img
                src="https://plantensive.com/wp-content/uploads/2020/10/team-project.jpg"
                alt=""
                className="w-100 p-1 border border-4"
              />
            </div>

            <div className="col-md-6 mt-4">
              <span className="title-h2 d-block text-dark-blue">
                <b>Strategic Advisory</b>
              </span>
              <div className="line mt-3 bg-orange"></div>

              <div className="mt-4">
                <p className="paragraph lh-lg">
                  Looking for a partner who can provide insight and
                  understanding to position your supply chain for superior
                  growth and optimization? We’ll work with you to create
                  high-level strategies and assess project feasibility and ROI.
                  Plantensive enjoys positive affirmation from the analyst
                  community for our planning operations and process consulting
                  methodologies.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5">
        <div className="container-box pt-md-5">
          <div className="top-heading mb-4">
            <p className="text-orange mb-1 text-uppercase">Our Case Studies</p>
            <span className="title-h2 d-block text-dark-blue">
              <b>CheckOut Our Case Study</b>
            </span>
          </div>

          <div className="row">
            <div className="col-md-4 mt-5 ">
              <div className="d-flex flex-wrap bd-industry-box">
                <div className="bd-top-portion">
                  <div>
                    <h3 className="paragraph">
                      Dairy Co-op Leverages Plantensive Demand Core Methodology
                      for Rapid Project Execution
                    </h3>
                    <div>
                      <Link to="#">Read Full Description</Link>
                    </div>
                  </div>
                </div>
                <div className="w-100 bg-black overflow-hidden">
                  <img
                    src={
                      "https://plantensive.com/wp-content/uploads/2019/11/dairy.png"
                    }
                    className="w-100 "
                    height="250"
                  />
                </div>
              </div>
            </div>

            <div className="col-md-4 mt-5 ">
              <div className="d-flex flex-wrap bd-industry-box">
                <div className="bd-top-portion">
                  <div>
                    <h3 className="paragraph">
                      Major Bottled Water Manufacturer Expands to Provide VMI
                      Services Powered by Plantensive
                    </h3>
                    <div>
                      <Link to="#">Read Full Description</Link>
                    </div>
                  </div>
                </div>
                <div className="w-100 bg-black overflow-hidden">
                  <img
                    src={
                      "https://plantensive.com/wp-content/uploads/2021/09/Major-Bottled-Water-Manufacturer-Expands-to-Provide-VMI-Services-Powered-by-Plantensive-1.png"
                    }
                    className="w-100 "
                    height="250"
                  />
                </div>
              </div>
            </div>

            <div className="col-md-4 mt-5 ">
              <div className="d-flex flex-wrap bd-industry-box">
                <div className="bd-top-portion">
                  <div>
                    <h3 className="paragraph">
                      North American Truck and Trailer Parts Distributor
                      Improves Readiness to Customer Demand.
                    </h3>
                    <div>
                      <Link to="#">Read Full Description</Link>
                    </div>
                  </div>
                </div>
                <div className="w-100 bg-black overflow-hidden">
                  <img
                    src={
                      "https://plantensive.com/wp-content/uploads/2019/11/truck.jpg"
                    }
                    className="w-100 "
                    height="250"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <CtaFooter />

      <Footer />
    </>
  );
};

export default SapIbp;
