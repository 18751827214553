import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Navbar from "../../Home/Navbar";
import Footer from "../../Home/Footer";
import "../../../assets/css/planning.css";

import demandBanner from "../../../assets/Images/demand-banner.jpg";
import { Config } from "../../../Config";
import axios from "axios";
import Loader from "../../../Components/Loader";

const Kinaxis = () => {
  const { kinaxisSelected } = useParams();

  const [loading, setLoading] = useState(true);
  const [kinaxinData, setKinaxinData] = useState([]);

  useEffect(() => {
    const getData = () => {
      setLoading(true);
      axios
        .get(`${Config.apiUrl}/kinaxis/${kinaxisSelected}`, {
          headers: {
            //'Authorization': 'Bearer ',
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setKinaxinData(response.data.data[0] ? response.data.data[0] : []);
        })
        .catch((error) => {
          console.error("There was a problem fetching the data:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    };
    getData();
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: smooth scrolling animation
    });
  }, [kinaxisSelected]);

  document.title = `${kinaxinData?.page_name} | plan4ai`;

  const data = {
    topSection: {
      title: kinaxinData.title,
      subTitle: kinaxinData.subtitle,
      desc: (
        <span dangerouslySetInnerHTML={{ __html: kinaxinData.description }} />
      ),
    },
    benefit: {
      title: kinaxinData.title2,
      subTitle: kinaxinData.subtitle2,
      desc: (
        <span dangerouslySetInnerHTML={{ __html: kinaxinData.description2 }} />
      ),
      features: [
        {
          icon: kinaxinData.img1,
          desc: kinaxinData.img_text1,
        },
        {
          icon: kinaxinData.img2,
          desc: kinaxinData.img_text2,
        },
        {
          icon: kinaxinData.img3,
          desc: kinaxinData.img_text3,
        },
        {
          icon: kinaxinData.img4,
          desc: kinaxinData.img_text4,
        },
      ],
    },
    cta: {
      title: kinaxinData.title3,
      desc: kinaxinData.subtitle3,
    },
  };

  return (
    <>
      {loading && <Loader />}
      <Navbar />
      <section
        className="page-header py-5"
        style={{
          backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.337), rgba(0, 0, 0, 0.57) 0%), url(${demandBanner})`,
        }}
      >
        <div className="container-box py-4">
          <div className="text-white">
            <h1 className="fw-bold display-5">{kinaxinData.page_name}</h1>
            <div className="line"></div>
            <p className="mt-4 header-path-link">
              <Link
                to={`${Config.appUrl}`}
                className="text-orange fw-bold text-decoration-none"
              >
                {" "}
                Home
              </Link>
              <i className="bi bi-arrow-right mx-3"></i>
              Solution
              <i className="bi bi-arrow-right mx-3"></i>
              {kinaxinData.page_name}
            </p>
          </div>
        </div>
      </section>
      <div>
        <section className="py-5">
          <div className="container-box py-md-4 py-mb-4">
            <div className="top-heading mb-4">
              <p className="text-orange mb-1 text-uppercase">
                {data.topSection.title}
              </p>
              <span className="title-h2 d-block text-dark-blue">
                <b>{data.topSection.subTitle}</b>
              </span>

              <p className="paragraph mt-3">{data.topSection.desc}</p>
            </div>
          </div>
        </section>
        <section className="pb-5 bg-planning">
          <div className="container-box py-md-5">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="top-heading mb-4">
                  <p className="text-orange mb-1 text-uppercase">
                    {data.benefit.title}
                  </p>
                  <span className="title-h2 d-block text-dark-blue">
                    <b>{data.benefit.subTitle} </b>
                  </span>
                </div>
                <div className="mt-4">
                  <p className="paragraph mt-3">{data.benefit.desc}</p>
                </div>
              </div>

              <div className="col-md-6 ps-md-5 mt-md-0 mt-5 mt-mb-0 text-md-start text-center">
                <div className="row">
                  {data.benefit.features.map((item, index) => {
                    if (index % 2 == 0) {
                      return (
                        <div className="col-md-6 analysis" key={index}>
                          <div className="mt-4 box-connect">
                            <div className="py-4 px-4">
                              <div className="border-plan">
                                <img src={item.icon} alt="" className="w-100" />
                              </div>
                              <p className="py-2 mb-0">{item.desc}</p>
                            </div>
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div className="col-md-6 analysis" key={index}>
                          <div className="mt-4 box-connect">
                            <div className="py-4 px-4">
                              <div className="border-plan">
                                <img src={item.icon} alt="" className="w-100" />
                              </div>
                              <p className="py-2 mb-0">{item.desc}</p>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="py-5 contact-bg">
          <div className="py-md-5 container-box">
            <div className=" cta-section text-center">
              <h2>{data.cta.title}</h2>
              <p className="paragraph text-white">{data.cta.desc}</p>

              <div className="mt-5">
                <Link
                  to={`${Config.appUrl}contact`}
                  className="style-btn shadow"
                >
                  <span>{" > "}</span>{" "}
                  <div className="d-inline">
                    {" "}
                    Contact With <b>plan4ai</b>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Kinaxis;
