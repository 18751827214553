import React, { useState, useEffect } from "react";
import Navbar from "../Home/Navbar";
import { Link } from "react-router-dom";
import Footer from "../Home/Footer";
import swal from "sweetalert";
import { Config } from "../../Config";
import axios from "axios";
import Loader from "../../Components/Loader";

const Contact = () => {
  const [loading, setLoading] = useState(true);
  const [contact, setContact] = useState({});

  document.title = "Contact Us | Plan4AI";

  const handleSubmitData = async (e) => {
    e.preventDefault();
    swal("Thank You!", "Your data has been sent successfully", {
      icon: "success",
    });
  };

  useEffect(() => {
    axios
      .get(`${Config.apiUrl}/contactpage`, {
        headers: {
          //'Authorization': 'Bearer ',
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const data = response.data.contact_page;
        setContact(data.contact);
      })
      .catch((error) => {
        console.error("There was a problem fetching the data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      {loading && <Loader />}
      <Navbar />

      <section
        className="page-header py-5"
        style={{
          backgroundImage:
            "linear-gradient(rgba(255, 255, 255, 0.337), rgba(0, 0, 0, 0.4) 0%), url('https://images.unsplash.com/photo-1510519138101-570d1dca3d66?q=80&w=1447&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
          backgroundPosition: "bottom",
        }}
      >
        <div className="container-box py-4">
          <div className="text-white">
            <h1 className="fw-bold display-5">Contact Us </h1>
            <div className="line"></div>
            <p className="mt-4 header-path-link">
              <Link
                to={`${Config.appUrl}`}
                className="text-orange fw-bold text-decoration-none"
              >
                {" "}
                Home
              </Link>
              <i className="bi bi-arrow-right mx-3"></i>
              Contact Us
            </p>
          </div>
        </div>
      </section>

      <section className="pt-5">
        <div className="container-box pt-md-4">
          <div className="row">
            <div className="col-md-12 ps-md-5">
              <div>
                <h2 className="text-dark-blue fs-1 fw-bold text-capitalize">
                  {contact.contact_form_title}
                </h2>
                <p className="paragraph">{contact.contact_form_subtitle}</p>

                <div className="py-4 row" id="direction-flex">
                  <div className=" col-md-3 text-center  pb-4">
                    <div className="contact-box">
                      <i className="bi bi-geo-alt fs-4 text-slik"></i>
                      <div className="">
                        <h5 className="fw-bold text-dark-blue text-uppercase">
                          USA Office
                        </h5>
                        <p className="mb-0 paragraph ">
                          {contact.contact_address_usa}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className=" col-md-3 text-center pb-4">
                    <div className="contact-box">
                      <i className="bi bi-geo-alt fs-4 text-slik"></i>
                      <div className="">
                        <h5 className="fw-bold text-dark-blue text-uppercase">
                          Bhopal Office
                        </h5>
                        <p className="mb-0 paragraph ">
                          {contact.contact_addresses}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className=" col-md-3 text-center pb-4" id="contct-us">
                    <div className="contact-box">
                      <i className="bi bi-whatsapp fs-4 text-slik"></i>
                      <div className=" contact-email">
                        <h5 className="fw-bold text-dark-blue text-uppercase">
                          Whatsapp Number
                        </h5>
                        <a
                          href="tel:3467121446"
                          className="paragraph text-decoration-none"
                        >
                          +1 {contact.contact_number_usa}
                        </a>
                        <br />
                        <a
                          href={`tel:${contact.contact_numbers}`}
                          className="paragraph text-decoration-none"
                        >
                          +91 {contact.contact_numbers}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className=" col-md-3 text-center pb-4">
                    <div className="contact-box">
                      <i className="bi bi-envelope-at fs-4 text-slik"></i>
                      <div className=" contact-email">
                        <h5 className="fw-bold text-dark-blue text-uppercase">
                          Email Address
                        </h5>
                        <a
                          href={`mailto:${contact.contact_mails}`}
                          className="text-decoration-none paragraph "
                        >
                          {contact.contact_mails}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-5">
        <div className="container-box pb-md-5">
          <div className="row justify-content-between px-md-5">
            <div className="col-md-12">
              <iframe
                src={contact.latitude}
                height="450"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                style={{
                  width: "100%",
                  height: "500px",
                }}
              ></iframe>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Contact;
