import React, { useRef, useState, useEffect } from "react";
import Navbar from "../Home/Navbar";
import { Link } from "react-router-dom";
import Footer from "../Home/Footer";
import Slider from "react-slick";
import CtaFooter from "../../Components/CtaFooter";
import { Config } from "../../Config";
import axios from "axios";
import Loader from "../../Components/Loader";

const AboutUs = () => {
  const [loading, setLoading] = useState(true);
  const [aboutIntro, setaboutIntro] = useState({});
  const [founding, setFounding] = useState([]);
  const [expert, setExpert] = useState([]);
  const [teamSection, setteamSection] = useState({});
  const [team, setTeam] = useState([]);

  const sliderRef = useRef();

  const blogsSettings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 3,
    swipeToSlide: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 985,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  document.title = "About Us | Plan4AI";

  useEffect(() => {
    axios
      .get(`${Config.apiUrl}/aboutpage`, {
        headers: {
          //'Authorization': 'Bearer ',
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const data = response.data.about_page;
        setaboutIntro(data.about_intro);
        setFounding(data.founding);
        setExpert(data.expert);
        setTeam(data.team);
        setteamSection(data.team_section);
        console.log(response);
      })
      .catch((error) => {
        console.error("There was a problem fetching the data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <Navbar />

          <section
            className="page-header py-5"
            style={{
              backgroundImage:
                "linear-gradient(rgba(255, 255, 255, 0.337), rgba(0, 0, 0, 0.6) 0%), url('https://images.unsplash.com/photo-1523961131990-5ea7c61b2107?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
              backgroundPosition: "center",
            }}
          >
            <div className="container-box py-4">
              <div className="text-white">
                <h1 className="fw-bold display-5">About Plan4ai</h1>
                <div className="line"></div>
                <p className="mt-4 header-path-link">
                  <Link
                    to={`${Config.appUrl}`}
                    className="text-orange fw-bold text-decoration-none"
                  >
                    {" "}
                    Home
                  </Link>
                  <i className="bi bi-arrow-right mx-3"></i>
                  About Us
                </p>
              </div>
            </div>
          </section>

          <section className="py-5 bg-style-img">
            <div className="container-box py-md-5">
              <div className="row">
                <div className="col-md-6 pe-md-5">
                  <div className="d-flex align-items-center">
                    <div className="col-sm-6 col-12 px-2">
                      <div>
                        <img
                          src={`${Config.imageUrl}/${aboutIntro.about_img}`}
                          alt=""
                          className="mb-h-100 w-100 object-fit-cover p-1 border border-4 about_2_image "
                        />

                        <img
                          src={`${Config.imageUrl}/${aboutIntro.about_img2}`}
                          alt=""
                          className="mb-d-none w-100 object-fit-cover p-1 border border-4 about_2_image mt-3"
                        />
                      </div>
                    </div>

                    <div className="col-6 px-2 mb-d-none">
                      <div>
                        <img
                          src={`${Config.imageUrl}/${aboutIntro.about_img3}`}
                          alt=""
                          className="w-100 object-fit-cover p-1 border border-4 about_1_image"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 mt-md-3 mt-5 px-mb-4">
                  <span className="title-h2 d-block text-dark-blue">
                    <b>{aboutIntro.about_text}</b>
                  </span>
                  <div className="line mt-3 bg-orange"></div>

                  <div className="mt-4">
                    <p className="paragraph lh-lg">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: aboutIntro.about_description,
                        }}
                      />
                    </p>

                    <div className="mt-5">
                      <a href="#our-expert" className="style-btn shadow">
                        <span>{" > "}</span>{" "}
                        <div className="d-inline">
                          {" "}
                          {aboutIntro.about_button_text}
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            className="py-5 bg-light-gray "
            style={{
              backgroundImage: `url(${Config.imageUrl}/${founding.founding_img})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="container-box py-md-5">
              <div className="row justify service-feature px-mb-4 ">
                <div className="col-md-6 ms-ds-4 ms-mb-0 bg-white p-5">
                  <div className="mt-3">
                    <i className="bi bi-file-earmark-person fs-2"></i>
                  </div>
                  <h5 className="fs-4 text-uppercase letter-spacing mb-4 ">
                    {founding.founding_text}
                  </h5>
                  <p className="paragraph mb-3">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: founding.founding_description,
                      }}
                    />
                  </p>
                </div>
              </div>
            </div>
          </section>

          <div id="our-expert">
            {expert.map((expert, i) => {
              const isFirstCol = i % 2 === 0;
              const lastI = expert.length - 1;
              return (
                <section key={expert.id} className="pt-5">
                  <div className="container-box py-md-5">
                    <div className="row">
                      {isFirstCol ? (
                        <>
                          <div className="col-md-6 pe-md-5">
                            <img
                              src={`${Config.imageUrl}/members/${expert.image}`}
                              alt=""
                              className="img-fluid  p-1 border border-4"
                            />
                          </div>

                          <div className="col-md-6 px-mb-4">
                            <span className="title-h2 d-block text-dark-blue mt-md-0 mt-4">
                              <b>{expert.title}</b>
                            </span>
                            <div className="line mt-3 bg-orange"></div>

                            <div className="mt-4">
                              <p className="paragraph lh-lg">
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: expert.description,
                                  }}
                                />
                              </p>

                              <div className="mt-4">
                                <div className="d-flex align-items-center">
                                  {/* <div>
                                    <img
                                      src={`${Config.imageUrl}/members/${expert.bg_img}`}
                                      alt=""
                                      style={{ height: "80px", width: "80px" }}
                                      className="rounded-circle object-fit-cover"
                                    />
                                  </div> */}
                                  <div
                                  // className="ms-4"
                                  >
                                    <h5 className="text-orange">
                                      {expert.name}
                                    </h5>
                                    <h5 className="text-secondary fs-6">
                                      {expert.position}
                                    </h5>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="col-md-6 px-mb-4 order-mb-2">
                            <span className="title-h2 d-block text-dark-blue mt-md-0 mt-4">
                              <b>{expert.title}</b>
                            </span>
                            <div className="line mt-3 bg-orange"></div>

                            <div className="mt-4">
                              <p className="paragraph lh-lg">
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: expert.description,
                                  }}
                                />
                              </p>

                              <div className="mt-4">
                                <div className="d-flex align-items-center">
                                  {/* <div>
                                    <img
                                      src={`${Config.imageUrl}/members/${expert.bg_img}`}
                                      alt=""
                                      style={{ height: "80px", width: "80px" }}
                                      className="rounded-circle object-fit-cover"
                                    />
                                  </div> */}
                                  <div
                                  // className="ms-4"
                                  >
                                    <h5 className="text-orange">
                                      {expert.name}
                                    </h5>
                                    <h5 className="text-secondary fs-6">
                                      {expert.position}
                                    </h5>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 pe-md-5 order-mb-1">
                            <img
                              src={`${Config.imageUrl}/members/${expert.image}`}
                              alt=""
                              className="img-fluid  p-1 border border-4"
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </section>
              );
            })}
          </div>
          <section>
            <div
              className="container-box pt-md-5"
              style={{ transform: "translateY(40px)" }}
            >
              <div className="px-md-5">
                <div className="d-md-flex justify-content-between align-items-center shadow-lg bg-dark p-4 px-md-5">
                  <div className="col-md-7">
                    <h2 className="mb-0  text-white">
                      Interested in being part of our team?
                    </h2>
                    <p className="paragraph mt-2 text-">
                      We're always looking for talented individuals to help us
                      build on our customer's success.
                    </p>
                  </div>

                  <div className="mt-md-0 mt-5 mb-md-0 mb-4">
                    <Link
                      to={`${Config.appUrl}contact`}
                      className="style-btn shadow"
                    >
                      <span>{" > "}</span>{" "}
                      <div className="d-inline">
                        {" "}
                        view open <b>positions</b>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      )}
    </>
  );
};

export default AboutUs;
